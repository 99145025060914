/* JS Version 1.2a */

import Swup from 'swup';
import SwupA11yPlugin from '@swup/a11y-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
//import Lenis from '@studio-freight/lenis'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger,SplitText);


/* Plugins */

let scrollPlugin = new SwupScrollPlugin({
    doScrollingRightAway: false,
    animateScroll: {
      betweenPages: false,
      samePageWithHash: true,
      samePage: true
    },
    scrollFriction: 0.4,
    scrollAcceleration: 0.02,
})



let swup = new Swup({
    animateHistoryBrowsing: true,
    plugins: [new SwupPreloadPlugin(), new SwupA11yPlugin()]
});



swup.hooks.on('content:replace', (container) => {
    main();
});


window.addEventListener('load', (event) => {  

    /* Preloader */
    setTimeout(removeLoader, 400); // Delay

    function removeLoader() {
        let preloader = document.querySelector('.preloader');

        if (preloader) {
            fadeOut(preloader, 300, function() {
                preloader.remove();
            });
        }

        main(); // run main for delayed animation

    };

    function fadeOut(el, duration, callback) {
        let opacity = 1;
        let interval = 5; // 5ms intervals
        let step = interval / duration;

        let fade = setInterval(function() {
            opacity -= step;
            el.style.opacity = opacity;

            if (opacity <= 0) {
                clearInterval(fade);
                if (typeof callback === 'function') {
                    callback();
                }
            }
        }, interval);
    };
});

function main(){

    /* Lazyload auto videos */

    let lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
    
    if ("IntersectionObserver" in window) {
        let lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(video) {
            if (video.isIntersecting) {
            for (let source in video.target.children) {
                let videoSource = video.target.children[source];
                if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.dataset.src;
                }
            }
    
            video.target.load();
            video.target.classList.remove("lazy");
            //console.log('swap');
            lazyVideoObserver.unobserve(video.target);
            }
        });
        });
    
        lazyVideos.forEach(function(lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
        });
    }


    /* Accordion */
    let readMore = document.getElementById('button-extra');

    if(readMore){ 
        readMore.addEventListener('click', function(){
            let extra = document.getElementById('content-extra');
            let t = this;

            if (extra.classList.contains('show')) {
                extra.classList.remove('show');
                extra.style.maxHeight = null;
                t.innerText = "Läs mer";
            } else {
                extra.classList.add('show');
                extra.style.maxHeight = extra.scrollHeight + "px";
                t.innerText = "Läs mindre";
            }
        });
    }

    /* Filter content frontpage */

    const textsContent = document.getElementById('content-texts');
    const caseContent = document.getElementById('content-case');

    const toggleTexts = document.getElementById('show-texts');
    const toggleCase = document.getElementById('show-case');

    function toggleContent(showContent, hideContent, activeButton, inactiveButton) {

        // Update active button style
        activeButton.classList.add('active-toggle');
        inactiveButton.classList.remove('active-toggle');

        // Fade out current content
        gsap.to(hideContent, {
          duration: 0.5,
          autoAlpha: 0,
          onComplete: () => {
            hideContent.style.display = 'none';
      
            // Fade in new content
            showContent.style.display = 'block';
            gsap.fromTo(showContent, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5 });

          }
        });
      }
      
      toggleTexts.addEventListener('click', () => toggleContent(textsContent, caseContent, toggleTexts, toggleCase));
      toggleCase.addEventListener('click', () => toggleContent(caseContent, textsContent, toggleCase, toggleTexts));      




    /* SPLITTEXT STUFF */

    const targets = gsap.utils.toArray(".ani-words");
    const targets2 = gsap.utils.toArray(".ani-char");

 /*    targets2.forEach((target) => {
        mySplitText = new SplitText(target, { type: "chars"  }),
        //lines = mySplitText.lines; //an array of all the divs that wrap each character
        chars = mySplitText.chars;
 */

        // chars, words, lines
        
        targets.forEach((target) => {
            mySplitText = new SplitText(target, { type: "words"  }),
            //mySplitText2 = new SplitText(target, { type: "lines", linesClass: "overflow" }),
            lines = mySplitText.words; //an array of all the divs that wrap each character


        gsap.from(lines, {
            duration: 1,
            opacity: 0,
            delay: 0.2,
            y: "0.4em",
            ease: "expo.out",
            stagger: 0.1
/*                 scrollTrigger: {
                trigger: target,
                start: "top 90%",
                end: "bottom 10%",
                toggleActions: "play none play none",
            } */
        });
    })


        /* Parallax stuff */

        let paraElementsOne = gsap.utils.toArray('.card__case:nth-child(5n + 1)');
        let paraElementsTwo = gsap.utils.toArray('.card__case:nth-child(5n + 2)');
        let paraElementsThree = gsap.utils.toArray('.card__case:nth-child(5n + 3)');
        let paraElementsFour = gsap.utils.toArray('.card__case:nth-child(5n + 4)');
        let paraElementsFive = gsap.utils.toArray('.card__case:nth-child(5n + 5)');
        
        let animations = []; // to store all GSAP animations
        let triggers = []; // to store all ScrollTriggers
        let hasRun = false; // flag to ensure it runs only once
        
        function checkWidth() {
            if (paraElementsOne.length === 0 || paraElementsTwo.length === 0 || paraElementsThree.length === 0 || paraElementsFour.length === 0 || paraElementsFive.length === 0) {
                return;
            }

            const isDesktop = window.matchMedia("(min-width: 769px)").matches;
        
            if (isDesktop && !hasRun) {
                hasRun = true;
        
                let animateElements = [
                    { elements: paraElementsOne, yPercent: 20, scrub: 1 },
                    { elements: paraElementsTwo, yPercent: -10, scrub: 0.5 },
                    { elements: paraElementsThree, yPercent: 10, scrub: 1.2 },
                    { elements: paraElementsFour, yPercent: 20, scrub: 0.7 },
                    { elements: paraElementsFive, yPercent: -5, scrub: 1.5 }
                ];
        
                animateElements.forEach(group => {
                    group.elements.forEach(element => {
                        let animation = gsap.to(element, {
                            yPercent: group.yPercent,
                            ease: "none",
                            scrollTrigger: {
                                trigger: element,
                                scrub: group.scrub,
                                onUpdate: function(self) {
                                    if (!isDesktop && self.isActive) {
                                        self.kill(); // Kill scrollTrigger if it becomes active on non-desktop
                                    }
                                }
                            }
                        });
        
                        animations.push(animation);
                        triggers.push(animation.scrollTrigger);
                    });
                });
        
            } else if (!isDesktop) {
                hasRun = false; // reset flag
        
                // kill all the animations in the array
                animations.forEach(animation => {
                    if (animation.isActive()) {
                        animation.kill();
                    }
                });

                triggers.forEach(trigger => trigger.kill()); // kill all ScrollTriggers

            }
        }
        
        checkWidth();
        window.addEventListener("resize", checkWidth);
        
}



// Credits
if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
    var args = ['%c Design & Development by CharlieP \u00A9 https://charliep.se \n','padding:10px 0;background: #5C00B9;color: #ffffff;'];
    window.console.log.apply(console, args);
} else if (window.console) {
    window.console.log('Design & Development by CharlieP - https://charliep.se');
}